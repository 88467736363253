import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="bodhi__bg-pattern">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 page-404 text-center">
            <h1>PAGE NOT FOUND</h1>
            <p>
              This link maybe outdated.{" "}
              <a href="https://bodhi.ac.nz">Return to the Home page here.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
